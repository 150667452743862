#promptBarWrapper{
    padding: .5rem 2rem;
    /* box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.5); */
}

#PromptBar {
    border-radius: 1.4rem !important;
    padding: .8rem 1.2rem !important;
    background-color: var(--color-light-contrast) !important;
}

#PromptSubmitBtn {
    border-radius: 1.4rem !important;
    height: 100%;
    padding: .6rem 1rem !important;
}