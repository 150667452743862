.messagesWrapper {
    height: calc(100vh - 177px);
    overflow-y: auto;
    width: 100%;
    padding: 0 2rem;
    padding-top: 3.5rem;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
}

.message {
    background-color: var(--color-light-contrast);
    border-radius: 1rem;
    padding: 1rem;
    width: fit-content;
    max-width: 880px;
}

.assistantMessage {
    background-color: transparent;
    padding: .5rem .5rem !important;
}

.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.expanded {
    display: block;
}

.showMoreWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -1.2rem;
    opacity: .9;
    background: var(--color-body-background);
    padding-top: 1rem;
}