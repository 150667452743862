:root {
  --color-primary: #5900ff;
  --color-primary-lighter: #5900ff40;
  --color-primary-hover: #5200eb;
  --color-text: #1e1f21;
  --color-text-lighter: #5d5e5f;
  --color-body-background: #fff;
  --color-light-gray: #F2F2F2;
  --color-light-contrast: #e3e3e3;
  --color-darker-gray: #D9D9D9;
  --color-danger: #FF3FFF;
  --color-success: #20c996aa !important;
  --toastify-color-success: #20c996aa !important;
  --scrollbar-background-color: #c6c6c6;
  --sidebar-background: #F2F2F2;
  --sidebar-width: 295px;
}

.dark-mode {
  --color-primary-lighter: #5900ffb1;
  --color-text: white;
  --color-text-lighter: #a5b0b9;
  --color-body-background: #212224;
  --sidebar-background: #1f2127;
  --color-light-contrast: #36383a;
  --scrollbar-background-color: #5f5f5f;
}

#sidebar.active {
  --sidebar-width: 0;
  margin-left: calc(0px - 295px);
}

a {
  text-decoration: none !important;
  color: var(--color-text) !important;
}

.bold {
  font-weight: bold !important;
}

.fw-500 {
  font-weight: 500 !important;
}

*:focus {
  box-shadow: none !important;
}

body {
  background-color: var(--color-body-background) !important;
  margin: 0;
  padding: 0;
  color: var(--color-text) !important;
  font-family: Helvetica, sans-serif !important;
  font-weight: 400;
  font-size: 12pt;
  font-style: normal;
}

.page-content-wrapper {
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.main-content-wrapper {
  width: 100%;
  overflow: auto;
  padding-left: var(--sidebar-width);
}

.main-container {
  min-height: 100vh;
  margin: auto;
  width: 100%;
}

.navbar-expand .navbar-nav .nav-link {
  font-weight: 500 !important;
  color: var(--color-text-lighter) !important;
  padding-left: .7rem !important;
  padding-right: .7rem !important;
}

.navbar-expand .navbar-nav .nav-link:hover,
.navbar-expand .navbar-nav .nav-link.active {
  color: var(--color-text) !important;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.btn-primary:hover {
  background-color: var(--color-primary-hover) !important;
  border-color: var(--color-primary-hover) !important;
}


.medium {
  font-size: 10.8pt !important;
}

.small {
  font-size: 11.5px !important;
}

.txt-primary {
  color: var(--color-primary) !important;
}

.txt-primary-gradient {
  background: linear-gradient(90deg, var(--color-primary), var(--color-text), var(--color-primary));
  -webkit-background-clip: text;
  color: transparent;
}

.txt-lighter {
  color: var(--color-text-lighter) !important;
}

.txt-danger {
  color: var(--color-danger) !important;
}

.txt-success {
  color: var(--color-success) !important;
}


.bgc-primary-lighter {
  background-color: var(--color-primary-lighter) !important;
}

.bgc-success {
  background-color: var(--color-success) !important;
}

.bgc-danger {
  background-color: var(--color-danger) !important;
}

.bgc-primary {
  background-color: var(--color-primary) !important;
}

.rounded-lg {
  border-radius: 1.4rem !important;
}

.form-control {
  border: 1.35px solid var(--bs-secondary) !important;
  background: transparent !important;
  transition: border-color 0.3s ease;
  padding-top: .6rem !important;
  padding-bottom: .6rem !important;
  color: var(--color-text) !important;
}

.form-control:focus {
  border-color: var(--color-text) !important;
}

::placeholder {
  color: var(--color-text-lighter) !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: var(--color-text-lighter) !important;
}

.main-content-wrapper {
  min-height: 90vh;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}


hr {
  border-top: 1.4px solid var(--color-light-contrast) !important;
  opacity: 1 !important;
}


p {
  margin-bottom: .3rem !important;
}

.nav-tabs {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
  overflow: auto;
  overflow-y: hidden;
  -ms-overflow-y: hidden;
}

p.two-words::after {
  content: "\A";
  white-space: pre;
}

.nav-tabs .nav-link {
  font-size: 9.5pt !important;
  text-align: left !important;
  padding-left: .5rem !important;
  border: 0 !important;
  background-color: transparent !important;
}

.hover:hover {
  background-color: var(--color-light-contrast);
}

.hover-lg:hover {
  background-color: #00000011 !important;
}

::-webkit-scrollbar {
  height: 6px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-background-color);
}

#sidebar {
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--sidebar-background);
  border-right: 1px solid var(--color-light-contrast);
  transition: all 0.3s;
  padding-top: 1.5rem;
  padding-bottom: 1.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

#toggleSidebarButton {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item.active,
.nav-item.active .nav-link,
.nav-item:hover,
.nav-item:hover .nav-link {
  color: var(--color-text) !important;
}

.nav-item:hover,
.nav-item.active {
  background-color: #00000011 !important;
}

.nav-item .nav-link {
  width: 100%;
  padding-top: 0.42rem;
  padding-bottom: 0.42rem;
  color: var(--color-text-lighter);
}

@media only screen and (max-width: 855px) {
  #sidebar {
    position: fixed !important;
    z-index: 9999 !important;
  }
}

.sidebar-link-group {
  padding-bottom: 1.85rem;
}

.nav-tabs .nav-item .nav-link {
  color: var(--color-secondary);
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-item .nav-link.active,
.nav-item .nav-link:hover {
  color: var(--color-dark) !important;
}

.table td,
.table th {
  padding: 1rem .3rem !important;
  font-size: 10.2pt !important;
  vertical-align: middle;
}

.btn-basic {
  background-color: var(--color-light-contrast) !important;
  color: var(--color-text) !important;
}

.btn-basic:hover {
  background-color: var(--color-darker-gray) !important;
}

.pointer {
  cursor: pointer;
}

li.disabled,
*::marker {
  display: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.modal-content {
  border: none !important;
  background-color: var(--color-light-contrast) !important;
}

.status-badge {
  width: 12px;
  height: 12px;
  background: var(--bs-teal);
  border-radius: 50%;
  position: absolute;
  bottom: -.2rem;
  right: -.2rem;
  border: 2px solid white;
}

.form-check-input {
  margin: 0 !important;
  background-color: var(--color-body-background) !important;
}

.form-check-input:checked {
  background-color: #1e1f21 !important;
  border-color: var(--color-text) !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: var(--color-light-contrast) !important;
  color: var(--color-text) !important;
}

p {
  margin-bottom: .3rem !important;
}

#accountDropdown {
  background-color: #fff !important;
  top: 30px;
}

.btn-outline-secondary {
  border-color: var(--color-darker-gray) !important;
}

.btn-outline-secondary:hover {
  background-color: var(--color-light-contrast) !important;
}

.card {
  background-color: var(--color-light-contrast) !important;
}

.card * {
  color: var(--color-text);
}

th,
td {
  background-color: var(--color-body-background) !important;
  color: var(--color-text) !important
}

.table>:not(caption)>*>* {
  border-color: var(--color-light-contrast) !important;
}